@import "https://p.typekit.net/p.css?s=1&k=hqi4nbh&ht=tk&f=44524.44525.44528.44529.44530.44531&a=2420667&app=typekit&e=css";
@font-face {
  font-family: degular-display;
  src: url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: degular-display;
  src: url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: degular-display;
  src: url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

.font-bk {
  font-family: degular-display, sans-serif;
}

:root {
  --bckgdColor: #1e1f29;
  --offBckgdColor: #020101;
  --primaryColor: #edefff;
  --secondaryColor: #9f7dd5;
  --gradientStartColor: #ffe569;
  --gradientEndColor: #ede1fe;
  --buttonColor: #9f7dd5;
  --buttonTextColor: #fff;
  --textColor: #1e1f29;
  --textSecondaryColor: #656565;
  --inputTextColor: #1e1f29;
  --inputColor: #edefff;
  --placeHolderColor: #a7a7a7;
  --successColor: #00bf6f;
  --errColor: #ff618a;
  --mobilePadding: 12px;
  --headerHeight: 90px;
  --fontFamily: "degular-display", sans-serif;
  --vh: 1vh;
  --gradientDeg: 0deg;
}

*, :after, :before {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.flex-container {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-between {
  justify-content: space-between;
}

.flex-gap {
  gap: 20px;
}

.no-display {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.no-scroll {
  overflow: hidden;
}

.no-pointers {
  pointer-events: none !important;
}

.ease-out {
  -o-transition: .3s ease-out;
  transition: all .3s ease-out;
}

.cubic-bezier {
  -o-transition: .7s cubic-bezier(.57, -.21, 0, .74);
  transition: all .7s cubic-bezier(.57, -.21, 0, .74);
}

.transform-y-100 {
  transform: translateY(-100%);
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: calc(50% - 10px);
}

.text-center {
  text-align: center;
}

.read-text {
  font-size: 1em;
  line-height: 1.5em;
}

.no-margin {
  margin: 0 !important;
}

.error {
  color: var(--errColor) !important;
}

.btns {
  font-family: var(--fontFamily);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  padding: 0;
}

button:disabled {
  opacity: .8;
  background-color: var(--buttonColor) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: var(--bckgdColor) !important;
  cursor: default !important;
}

.h1 {
  font-size: 60px;
  font-family: var(--fontFamily);
  color: var(--textColor);
  line-height: 1em;
}

.spinner, .spinner:before {
  border-radius: 50%;
}

.spinner {
  color: var(--buttonTextColor);
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.spinner:before {
  content: "";
  background: var(--buttonColor);
  transform-origin: 10.4px 10.2px;
  -webkit-animation: 2s 1.5s infinite loading;
  border-radius: 20.4px 0 0 20.4px;
  width: 10.4px;
  height: 20.4px;
  animation: 1s linear infinite loading;
  position: absolute;
  top: -.2px;
  left: -.2px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

html, body {
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--bckgdColor);
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  min-height: calc(100 * var(--vh));
  font-family: var(--fontFamily);
  font-size: 1.2rem;
  line-height: 1.5;
}

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

@keyframes gradientAnim {
  0% {
    --a: 0deg;
  }

  100% {
    --a: 360deg;
  }
}

.container {
  --a: 0deg;
  background: linear-gradient(var(--a), #ffe569 10%, #fff 20%, #eee1ff 50%);
  min-height: calc(100 * var(--vh));
  animation: 20s linear infinite gradientAnim;
}

.logo-100 {
  width: 100%;
}

.logo-big {
  height: 85.9206px;
}

.logo-small {
  height: 50px;
}

.logo path {
  fill: var(--bckgdColor);
}

.logo-collapsed {
  height: 50px !important;
}

#boxWrapper {
  height: calc(100 * var(--vh));
  gap: 20px;
  width: 100%;
}

#box {
  text-align: center;
  width: calc(100% - 40px);
  max-width: 420px;
  padding: 20px;
}

#box a:link, #box a:visited {
  color: var(--textColor);
  font-weight: 600;
  text-decoration: none;
}

#box h1, #box h2, #box p {
  color: var(--textColor);
}

#resultOverlay {
  z-index: 999;
}

.overlay {
  height: calc(100 * var(--vh));
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background-color: #1e1e1ee6;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay-change {
  visibility: visible !important;
  opacity: 1 !important;
}

.overlay-box {
  max-height: max(calc(100 * var(--vh))  - 20px, 500px);
  background-color: var(--bckgdColor);
  color: var(--primaryColor);
  cursor: default;
  border-radius: 6px;
  width: calc(100% - 20px);
  max-width: 400px;
  height: auto;
  padding: 2rem;
  overflow-y: scroll;
  transform: scale(0);
}

.overlay-box-change {
  transform: scale(1);
}

.overlay-box h1 {
  text-align: center;
}

.overlay-box p {
  text-align: center;
  margin: 2em 0;
}

.overlay-content-sticky-header {
  background-color: var(--bckgdColor);
  z-index: 1;
  width: 100%;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
}

.form-w-100 {
  width: 100%;
}

.input-labels {
  color: var(--textColor);
  display: block;
}

.input-labels span {
  font-size: .7em;
}

.input-fields {
  color: var(--inputTextColor);
  background-color: var(--inputColor);
  font-family: var(--fontFamily);
  border: none;
  border-radius: .25rem;
  outline: none;
  width: 100%;
  max-width: 320px;
  height: 40px;
  margin: 10px 0 0;
  padding: 10px;
  font-size: 1rem;
}

.input-readonly {
  cursor: default;
  background-color: var(--buttonColor);
}

.form .input-readonly {
  background-color: var(--gradientStartColor) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.form textarea:invalid, .form textarea:invalid:hover, .form input:invalid, .form input:invalid:hover {
  border: 2px solid var(--errColor);
  -webkit-box-shadow: 0px 1px 5px var(--errColor);
  box-shadow: 0px 1px 5px var(--errColor);
}

::placeholder {
  color: var(--placeHolderColor);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--placeHolderColor);
}

::-moz-placeholder {
  color: var(--placeHolderColor);
}

.submit-btn {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border-radius: 100vmax;
  width: 100%;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 400;
  display: inline-block;
}

.success-btn, .error-btn {
  width: 100%;
}

.error-btn {
  background-color: var(--errColor) !important;
  color: var(--bckgdColor) !important;
}

.login-container {
  min-height: calc(100 * var(--vh));
  justify-content: center;
  width: 100%;
}

.login-overlay {
  width: calc(100% - var(--mobilePadding));
  align-items: center;
  max-height: 700px;
  padding: 20px;
}

.login-overlay h1 {
  color: var(--textColor);
}

#loginForm {
  width: 100%;
  max-width: 200px;
}

#signupForm {
  align-items: center;
  width: 100%;
  max-width: 290px;
}

#signupBtn {
  width: 100%;
}

.pass-wrapper {
  align-items: center;
  width: 100%;
}

#loginBtn, #forgotBtn {
  width: 100%;
  margin: 10px 0;
}

.show-btn {
  background-color: var(--inputColor);
  color: var(--inputTextColor);
  border: none;
  border-radius: 0 6px 6px 0;
  outline: none;
  height: 40px;
  margin-top: 10px;
  margin-left: -6px;
  padding: 0 10px;
  font-weight: 100;
}

#forgotPass {
  text-align: center;
  color: var(--textColor);
  cursor: pointer;
  margin: 0;
  font-size: .8em;
}

.success-logout, .error-logout {
  text-align: center;
  color: var(--bckgdColor);
  border-radius: 5px;
  height: 40px;
  margin: 20px 0;
  padding: 5px 10px;
}

.success-logout {
  background-color: var(--successColor);
}

.error-logout {
  background-color: var(--errColor);
}

#settingsOverlay #logOutBtn {
  width: 100%;
  margin: 20px 0;
}

#settingsOverlay .overlay-box {
  width: calc(100% - var(--mobilePadding));
}

#settingsOverlay .overlay-content {
  background-color: var(--headerColor);
}

.settings-item {
  background-color: var(--primaryColor);
  text-align: center;
  color: var(--textColor);
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  margin: 5px 0;
  padding: 5px 15px;
  text-decoration: none;
}

#settingsView {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  max-width: 1040px;
  margin: 0 auto;
  position: relative;
}

#settingsView h1, #settingsView h2 {
  color: var(--textColor);
}

#settingsView form {
  width: 100%;
  max-width: 290px;
  margin-top: 20px;
  display: block;
}

#settingsWrapper {
  padding-bottom: 100px;
}

#settingsWrapper p {
  color: var(--textColor);
  max-width: 320px;
}

.setting-page {
  width: 100%;
}

#changePassForm, #newPassCriteria {
  width: 100%;
  max-width: 290px;
}

#newPassCriteria {
  margin: 10px 0;
}

#newPassCriteria p {
  color: var(--textColor);
}

#changePassForm label {
  font-size: 1em;
}

#resetPassBtn, #inviteBtn, #signupBtn {
  width: 100%;
  margin: 10px 0;
}

#resetPassBtn {
  margin-top: 0;
}

.pass-criteria-wrapper {
  background-color: var(--inputColor);
  border-radius: 6px;
  padding: 5px 10px;
  font-size: .8em;
}

.pass-criteria {
  margin: 10px 0;
  font-weight: 400;
}

.pass-criteria span {
  color: var(--textSecondaryColor);
}

.pass-criteria-success {
  color: var(--secondaryColor) !important;
}

.pass-criteria-text {
  margin-left: 10px;
}

.reset-pass:disabled, #signupBtn:disabled {
  color: var(--bckgdColor) !important;
}

.error-btn {
  background-color: var(--errColor) !important;
}

.header {
  height: var(--headerHeight);
  z-index: 10;
  -o-transition: height .7s cubic-bezier(.57, -.21, 0, .74), box-shadow .7s cubic-bezier(.57, -.21, 0, .74);
  width: 100%;
  transition: height .7s cubic-bezier(.57, -.21, 0, .74), box-shadow .7s cubic-bezier(.57, -.21, 0, .74);
  position: fixed;
  top: 0;
}

.header-content {
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
}

.header-flex {
  gap: 20px;
}

.header-collapsed {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: #0000;
}

.header-settings {
  justify-content: end;
  align-items: center;
}

#reOrderBtn {
  max-width: 100px;
}

.page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 150px 20px 100px;
  position: relative;
}

.forms-wrapper {
  justify-content: flex-start;
  align-items: flex-start;
}

.form {
  background-color: var(--buttonTextColor);
  border-radius: 10px;
  gap: 20px;
  width: 100%;
  padding: 40px 20px 20px;
  position: relative;
}

.form-row {
  align-items: flex-end;
}

.form-col {
  max-width: 300px;
}

.form-col-large {
  max-width: 600px;
}

.form-row .submit-btn {
  width: 120px !important;
}

.input-labels {
  font-size: 1.2em;
  display: block;
}

.input-labels-small {
  font-size: 1em !important;
}

.grow-wrap {
  display: -ms-grid;
  width: 100%;
  display: grid;
}

.grow-wrap:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}

.grow-wrap > textarea, .grow-wrap:after {
  grid-area: 1 / 1 / 2 / 2;
  margin: 10px 0;
  font-size: .8em;
  height: unset !important;
  padding: .5rem !important;
}

textarea {
  resize: none;
  flex-grow: 1;
  min-height: 100px !important;
}

.textarea-flex {
  height: 100%;
}

.custom-input-wrapper {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 40px;
  margin-top: 10px;
  padding-left: 35px;
  font-size: 1em;
  font-weight: 600;
  display: block;
  position: relative;
}

.custom-input-wrapper input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.checkmark {
  background-color: var(--inputColor);
  border-radius: 5px;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.checkmark:hover {
  background-color: var(--gradientEndColor);
}

.custom-input-wrapper input:checked ~ .checkmark {
  background-color: var(--secondaryColor);
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.custom-input-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.custom-input-wrapper .checkmark:after {
  border: solid var(--inputColor);
  border-width: 0 3px 3px 0;
  width: 8px;
  height: 16px;
  top: 3px;
  left: 9px;
  transform: rotate(45deg);
}

.add-input-btn {
  margin-top: 10px;
}

.delete-input-btn {
  width: 20px;
  margin-top: 10px;
  margin-left: 10px;
}

.add-btn svg {
  width: 40px;
  height: 40px;
}

.add-input-btn svg {
  width: 25px;
  height: 25px;
}

.delete-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.delete-btn svg path, .add-input-btn svg path, .delete-input-btn svg path {
  fill: var(--bckgdColor);
}

.delete-btn svg {
  width: 30px;
  height: 30px;
}

.prompt-btns {
  gap: 20px;
}

.prompt-btns .submit-btn {
  width: calc(100% - 40px) !important;
}

.form .input-readonly {
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-item:link, .dashboard-item:visited {
  color: var(--bckgdColor);
  text-decoration: none;
}

.dashboard-item {
  background-color: var(--primaryColor);
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  padding: 20px;
}

.dashboard-item h1 {
  color: var(--bckgdColor);
  position: relative;
  top: -5px;
}

.pricing-wrapper {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.pricing-flex {
  background-color: var(--gradientEndColor);
  gap: 30px;
  width: 100%;
  padding: 10px;
}

.ensemble-label {
  color: var(--bckgdColor);
  place-self: center;
  font-weight: bold;
}

.dropzone {
  background-color: var(--inputColor);
  border-radius: 5px;
  width: 100%;
  padding: 20px;
}

.dropzone .upload-btn {
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.dropzone-change {
  background-color: var(--gradientEndColor);
}

.file-reveal {
  background-color: var(--bckgdColor);
  border-radius: 5px;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}

.file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primaryColor);
  overflow: hidden;
}

.remove-file-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
}

.uploaded-img {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.testimonial-form img {
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
}

.progress-wrapper {
  gap: 10px;
  padding: 20px;
}

.progress-wrapper p {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.progress {
  background-color: var(--inputColor);
  border-radius: 100vmax;
  width: 100%;
  height: 10px;
  overflow: hidden;
}

.progress-bar {
  background-color: var(--buttonColor);
  width: 0%;
  height: 10px;
}

.send-btn {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 400;
}

.send-btn:disabled {
  background-color: var(--bckgdColor) !important;
  color: var(--textSecondaryColor) !important;
}

.help-tip {
  text-align: center;
  background-color: var(--secondaryColor);
  cursor: default;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 0 !important;
}

.help-tip:before {
  content: "i";
  color: var(--inputColor);
  font-family: serif;
  font-size: 1.3em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.6;
}

.help-tip:hover p {
  opacity: 1;
  transform: scale(1);
}

.help-tip p {
  opacity: 0;
  transform-origin: 0 0;
  text-align: left;
  background-color: var(--primaryColor);
  color: var(--textColor);
  z-index: 1;
  border-radius: 3px;
  width: 300px;
  padding: 20px;
  line-height: 1.5em;
  position: relative;
  transform: scale(0);
  box-shadow: 1px 1px 10px #0003;
}

.help-tip p:after {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  top: -17px;
  right: 0;
}

.help-tip a:link, .help-tip a:visited {
  color: var(--bckgdColor);
  font-weight: 700;
}

.bookings-wrapper {
  align-items: stretch;
}

.booking {
  background-color: var(--buttonTextColor);
  border-radius: 10px;
  gap: 20px;
  width: 100%;
  max-width: 300px;
  padding: 20px;
}

.booking-label {
  color: var(--bckgdColor);
  font-weight: 600;
}

.booking-value {
  word-break: break-word;
  text-align: right;
}

.reorder-wrapper {
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.reorder-item {
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}

#submitReorderBtn {
  margin-top: 20px;
}

.draggable {
  cursor: move;
}

.draggable.dragging {
  opacity: .7;
  background-color: var(--gradientStartColor) !important;
}

@media (width <= 768px) {
  :root {
    --headerHeight: 50px !important;
  }

  .header-content {
    padding: 0 10px 0 0;
  }

  .header-flex {
    gap: 10px;
  }

  .header .logo-big {
    height: 50px !important;
  }

  #userName {
    display: none;
  }

  .page {
    padding-top: 70px;
  }

  .h1 {
    font-size: 40px;
  }

  .form {
    max-width: unset !important;
  }

  .form-flex {
    flex-direction: column;
  }

  .form .input-fields {
    max-width: unset !important;
  }

  .form-row {
    align-items: flex-start;
  }

  .form-row .submit-btn {
    width: 100% !important;
  }

  .custom-input-wrapper {
    -ms-grid-row-align: flex-start;
    -ms-grid-column-align: flex-start;
    place-self: flex-start;
  }

  .width-50 {
    width: 100%;
  }
}

@media (width <= 414px) {
  .header h2 {
    font-size: 1em;
  }

  .help-tip p {
    width: 200px;
    left: -100%;
  }
}

@media (hover: hover) {
  .send-btn:hover, .send-btn:focus, .submit-btn:hover, .submit-btn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--buttonColor);
    box-shadow: 0px 1px 10px var(--buttonColor);
  }

  .error-btn:hover, .error-btn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--errColor);
    box-shadow: 0px 1px 10px var(--errColor);
  }

  .settings-item:hover, .settings-item:focus {
    background-color: var(--gradientEndColor);
  }

  #settingsBtn:hover svg path {
    fill: var(--buttonColor);
  }

  #forgotPass:hover, #forgotPass:focus {
    color: var(--textSecondaryColor);
    text-decoration: underline;
  }

  #box a:hover, #box a:focus {
    text-decoration: underline;
  }

  .dashboard-item:hover {
    box-shadow: 0 1px 10px #1e1f292b;
  }

  .add-btn:hover svg path, .delete-btn:hover svg path, .delete-input-btn:hover svg path, .add-input-btn:hover svg path {
    fill: var(--buttonColor);
  }

  .remove-file-btn:hover path {
    fill: var(--textSecondaryColor);
  }

  .reorder-item:hover {
    background-color: var(--gradientEndColor);
  }
}

@supports ((-webkit-backdrop-filter: none)) or ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .overlay {
    background-color: var(--overlayColor);
    backdrop-filter: blur(15px);
  }
}

/*# sourceMappingURL=index.610c059b.css.map */
