/* BEGIN FONTS */
@import url("https://p.typekit.net/p.css?s=1&k=hqi4nbh&ht=tk&f=44524.44525.44528.44529.44530.44531&a=2420667&app=typekit&e=css");

@font-face {
    font-family: "degular-display";
    src: url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/3e504d/00000000000000007735c85b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}


@font-face {
    font-family: "degular-display";
    src: url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/9dedee/00000000000000007735c86a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
}


@font-face {
    font-family: "degular-display";
    src: url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/90e1f3/00000000000000007735c870/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

.font-bk {
    font-family: "degular-display", sans-serif;
}

/* END FONTS */
/*  ------- BEGIN GLOBALS */
:root {
    /* GLOBAL COLORS */
    --bckgdColor: #1e1f29;
    --offBckgdColor: #020101;
    --primaryColor: #edefff;
    --secondaryColor: #9f7dd5;
    --gradientStartColor: #ffe569;
    --gradientEndColor: #ede1fe;
    --buttonColor: #9f7dd5;
    --buttonTextColor: #fff;
    --textColor: #1e1f29;
    --textSecondaryColor: #656565;
    --inputTextColor: #1e1f29;
    --inputColor: #edefff;
    --placeHolderColor: #a7a7a7;
    --successColor: #00bf6f;
    --errColor: #ff618a;
    --mobilePadding: 12px;
    --headerHeight: 90px;
    --fontFamily: "degular-display", sans-serif;
    --vh: 1vh;
    --gradientDeg: 0deg;
}

*,
:after,
:before {
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* TO REMOVE SCROLLBAR */
    scrollbar-width: none;
    /* Firefox */
}

/* BEGIN REMOVE SCROLLBARS */
*::-webkit-scrollbar {
    display: none;
}

/* END REMOVE SCROLLBARS */

.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-no-shrink {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.flex-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.flex-gap {
    gap: 20px;
}

.no-display {
    display: none !important;
}

.hidden {
    visibility: hidden !important;
}

.no-scroll {
    overflow: hidden;
}

.no-pointers {
    pointer-events: none !important;
}

.ease-out {
    -o-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.cubic-bezier {
    -o-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
}

.transform-y-100 {
    -o-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.width-100 {
    width: 100%;
}

.width-50 {
    width: calc(50% - 10px);
}

.text-center {
    text-align: center;
}

.read-text {
    line-height: 1.5em;
    font-size: 1em;
}

.no-margin {
    margin: 0 !important;
}

.error {
    color: var(--errColor) !important;
}

.btns {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    font-family: var(--fontFamily);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
}

button:disabled {
    opacity: 0.8;
    background-color: var(--buttonColor) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: var(--bckgdColor) !important;
    cursor: default !important;
}

.h1 {
    font-size: 60px;
    font-family: var(--fontFamily);
    color: var(--textColor);
    line-height: 1em;
}


/* ------- BEGIN BUTTON SPINNER LOADER */
.spinner,
.spinner:before {
    border-radius: 50%;
}

.spinner {
    color: var(--buttonTextColor);
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 0 2px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}

.spinner:before {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--buttonColor);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    -ms-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 1s infinite linear;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ------- END BUTTON SPINNER LOADER */
html,
body {
    margin: 0;
    padding: 0;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

html {
    background-color: var(--bckgdColor);
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body {
    /*  background-image: linear-gradient(var(--gradientStartColor), var(--gradientEndColor)); */
    min-height: calc(100 * var(--vh));
    font-family: var(--fontFamily);
    font-size: 1.2rem;
    line-height: 1.5;

}

@property --a {
    syntax: '<angle>';
    inherits: false;
    initial-value: 0deg;
}

@-webkit-keyframes gradientAnim {
    0% {
        --a: 0deg;
    }

    100% {
        --a: 360deg;
    }
}

@keyframes gradientAnim {
    0% {
        --a: 0deg;
    }

    100% {
        --a: 360deg;
    }
}

.container {
    --a: 0deg;
    background: linear-gradient(var(--a), rgb(255, 229, 105) 10%, rgb(255, 255, 255) 20%, rgb(238, 225, 255) 50%);
    -webkit-animation: gradientAnim 20s infinite linear;
    animation: gradientAnim 20s infinite linear;
    min-height: calc(100 * var(--vh));
}


.logo-100 {
    width: 100%;
}

.logo-big {
    height: 85.9206px;
}

.logo-small {
    height: 50px;
}

.logo path {
    fill: var(--bckgdColor);
}

.logo-collapsed {
    height: 50px !important;
}

/*  ------- END GLOBALS */
/* ------- BEGIN 404 PAGE */


#boxWrapper {
    width: 100%;
    height: calc(100 * var(--vh));
    gap: 20px;
}

#box {
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 420px;
    text-align: center;
}

#box a:link,
#box a:visited {
    text-decoration: none;
    font-weight: 600;
    color: var(--textColor);
}

#box h1,
#box h2,
#box p {
    color: var(--textColor);
}

/* ------- END 404 PAGE */

/* ------- BEGIN OVERLAY */
#resultOverlay {
    z-index: 999;
}

.overlay {
    width: 100%;
    height: calc(100 * var(--vh));
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(30 30 30 / 90%);
    cursor: pointer;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
}

.overlay-change {
    visibility: visible !important;
    opacity: 1 !important;
}

.overlay-box {
    height: auto;
    max-height: max(calc(100 * var(--vh)) - 20px, 500px);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    max-width: 400px;
    width: calc(100% - 20px);
    padding: 2rem;
    background-color: var(--bckgdColor);
    color: var(--primaryColor);
    border-radius: 6px;
    overflow-y: scroll;
    cursor: default;
}

.overlay-box-change {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.overlay-box h1 {
    text-align: center;
}

.overlay-box p {
    text-align: center;
    margin: 2em 0;
}

.overlay-content-sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--bckgdColor);
    padding-bottom: 20px;
    z-index: 1;
}


/* ------- END OVERLAY */
/* ------- BEGIN INPUT TYPES */
.form-w-100 {
    width: 100%;
}

.input-labels {
    display: block;
    color: var(--textColor);
}

.input-labels span {
    font-size: 0.7em;
}

.input-fields {
    border: none;
    outline: none;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 320px;
    height: 40px;
    color: var(--inputTextColor);
    background-color: var(--inputColor);
    margin: 10px 0 0 0;
    padding: 10px;
    font-family: var(--fontFamily);
    font-size: 1rem;
}

.input-readonly {
    cursor: default;
}

.input-readonly {
    background-color: var(--buttonColor);
}

.form .input-readonly {
    background-color: var(--gradientStartColor) !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--inputTextColor);
    -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
    box-shadow: 0 0 0px 1000px var(--inputColor) inset;
    -o-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.form textarea:invalid,
.form textarea:invalid:hover,
.form input:invalid,
.form input:invalid:hover {
    border: 2px solid var(--errColor);
    -webkit-box-shadow: 0px 1px 5px var(--errColor);
    box-shadow: 0px 1px 5px var(--errColor);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeHolderColor);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeHolderColor);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeHolderColor);
}

.submit-btn {
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    background-color: var(--buttonColor);
    color: var(--buttonTextColor);
    padding: 10px 20px;
    border-radius: 100vmax;
    width: 100%;
}

.success-btn,
.error-btn {
    width: 100%;
}

.error-btn {
    background-color: var(--errColor) !important;
    color: var(--bckgdColor) !important;
}

/* ------- END INPUT TYPES */
/* ---------- BEGIN LOGIN */
.login-container {
    width: 100%;
    min-height: calc(100 * var(--vh));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-overlay {
    width: calc(100% - var(--mobilePadding));
    max-height: 700px;
    padding: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login-overlay h1 {
    color: var(--textColor);
}

#loginForm {
    width: 100%;
    max-width: 200px;
}

#signupForm {
    width: 100%;
    max-width: 290px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#signupBtn {
    width: 100%;
}

.pass-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

#loginBtn,
#forgotBtn {
    width: 100%;
    margin: 10px 0;
}

.show-btn {
    border: none;
    outline: none;
    background-color: var(--inputColor);
    color: var(--inputTextColor);
    height: 40px;
    margin-left: -6px;
    margin-top: 10px;
    border-radius: 0px 6px 6px 0px;
    padding: 0 10px;
    font-weight: 100;
}

#forgotPass {
    text-align: center;
    color: var(--textColor);
    font-size: 0.8em;
    margin: 0;
    cursor: pointer;
}

.success-logout,
.error-logout {
    height: 40px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0;
    color: var(--bckgdColor);

}

.success-logout {
    background-color: var(--successColor);
}

.error-logout {
    background-color: var(--errColor);
}

/* ---------- END LOGIN */
/* ---------- BEGIN SETTINGS */
/* SETTINGS OVERLAY */
#settingsOverlay #logOutBtn {
    margin: 20px 0;
    width: 100%;
}

#settingsOverlay .overlay-box {
    width: calc(100% - var(--mobilePadding));
}

#settingsOverlay .overlay-content {
    background-color: var(--headerColor);
}

.settings-item {
    width: 100%;
    height: 50px;
    padding: 5px 15px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: var(--primaryColor);
    text-align: center;
    text-decoration: none;
    color: var(--textColor);
    cursor: pointer;
}

/* SETTINGS VIEW */
#settingsView {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
}

#settingsView h1,
#settingsView h2 {
    color: var(--textColor);
}

#settingsView form {
    display: block;
    width: 100%;
    max-width: 290px;
    margin-top: 20px;
}

#settingsWrapper {
    padding-bottom: 100px;
}

#settingsWrapper p {
    color: var(--textColor);
    max-width: 320px;
}

.setting-page {
    width: 100%;
}

/* CHANGE PASSWORD */
#changePassForm,
#newPassCriteria {
    width: 100%;
    max-width: 290px;
}

#newPassCriteria {
    margin: 10px 0;
}

#newPassCriteria p {
    color: var(--textColor);
}

#changePassForm label {
    font-size: 1em;
}

#resetPassBtn,
#inviteBtn,
#signupBtn {
    width: 100%;
    margin: 10px 0;

}

#resetPassBtn {
    margin-top: 0;
}

.pass-criteria-wrapper {
    padding: 5px 10px;
    background-color: var(--inputColor);
    border-radius: 6px;
    font-size: 0.8em;
}

.pass-criteria {
    margin: 10px 0;
    font-weight: 400;
}

.pass-criteria span {
    color: var(--textSecondaryColor);
}

.pass-criteria-success {
    color: var(--secondaryColor) !important;
}

.pass-criteria-text {
    margin-left: 10px;
}

.reset-pass:disabled,
#signupBtn:disabled {
    color: var(--bckgdColor) !important;
}

/* ---------- END SETTINGS */

.error-btn {
    background-color: var(--errColor) !important;
}

/* ---------- BEGIN HEADER */
.header {
    position: fixed;
    top: 0px;
    width: 100%;
    height: var(--headerHeight);
    z-index: 10;
    -o-transition: height .7s cubic-bezier(0.57, -0.21, 0, 0.74), box-shadow .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: height .7s cubic-bezier(0.57, -0.21, 0, 0.74), box-shadow .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: height .7s cubic-bezier(0.57, -0.21, 0, 0.74), box-shadow .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: height .7s cubic-bezier(0.57, -0.21, 0, 0.74), box-shadow .7s cubic-bezier(0.57, -0.21, 0, 0.74);
}

.header-content {
    width: 100%;
    max-width: 1400px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 20px;
}

.header-flex {
    gap: 20px;
}

.header-collapsed {
    background-color: transparent;
    backdrop-filter: blur(50px);
}


.header-settings {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

#reOrderBtn {
    max-width: 100px;
}

/* ---------- END HEADER */
/* ---------- BEGIN CONTENT */
.page {
    position: relative;
    padding: 150px 20px 100px;
    max-width: 1400px;
    margin: 0 auto;
}

.forms-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

/* BEGIN FORMS */
.form {
    position: relative;
    background-color: var(--buttonTextColor);
    padding: 40px 20px 20px;
    border-radius: 10px;
    width: 100%;
    gap: 20px;
}

.form-row {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.form-col {
    max-width: 300px;
}

.form-col-large {
    max-width: 600px;
}

.form-row .submit-btn {
    width: 120px !important;
}

.input-labels {
    display: block;
    font-size: 1.2em;
}

.input-labels-small {
    font-size: 1em !important;
}


/* BEGIN HANDLE TEXTAREA */
.grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: -ms-grid;
    display: grid;
    width: 100%;
}

.grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}

.grow-wrap>textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
    /* Identical styling required!! */
    padding: 0.5rem !important;
    font-size: 0.8em;
    height: unset !important;
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
    margin: 10px 0;
}

textarea {
    resize: none;
    min-height: 100px !important;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    /*  padding: 5px 10px !important; */
}

.textarea-flex {
    height: 100%;
}

/* END HANDLE TEXTAREA */
/* BEGIN CUSTOM CHECKBOX  */
.custom-input-wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-input-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--inputColor);
    border-radius: 5px;
}

.checkmark:hover {
    background-color: var(--gradientEndColor);
}

.custom-input-wrapper input:checked~.checkmark {
    background-color: var(--secondaryColor);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-input-wrapper input:checked~.checkmark:after {
    display: block;
}

.custom-input-wrapper .checkmark:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 16px;
    border: solid var(--inputColor);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* END CUSTOM CHECKBOX AND RADIO INPUTS */
.add-input-btn {
    margin-top: 10px;
}

.delete-input-btn {
    width: 20px;
    margin-top: 10px;
    margin-left: 10px;
}

.add-btn svg {
    width: 40px;
    height: 40px;
}

.add-input-btn svg {
    width: 25px;
    height: 25px;
}

.delete-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.delete-btn svg path,
.add-input-btn svg path,
.delete-input-btn svg path {
    fill: var(--bckgdColor);
}

.delete-btn svg {
    width: 30px;
    height: 30px;
}

.prompt-btns {
    gap: 20px;
}

.prompt-btns .submit-btn {
    width: calc(100% - 40px) !important;
}

.form .input-readonly {
    font-weight: 600;
    text-align: center;
    font-size: 1.5em;
}

/* END FORMS */
/* BEGIN INDEX PAGE */
.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px;
}

.dashboard-item:link,
.dashboard-item:visited {
    text-decoration: none;
    color: var(--bckgdColor);
}

.dashboard-item {
    padding: 20px;
    border-radius: 10px;
    background-color: var(--primaryColor);
    width: 100%;
    max-width: 300px;
    cursor: pointer;
}

.dashboard-item h1 {
    color: var(--bckgdColor);
    position: relative;
    top: -5px;
}

/* END INDEX PAGE */
/* BEGIN EVENTS PAGE */
.pricing-wrapper {
    margin-top: 10px;
    gap: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.pricing-flex {
    gap: 30px;
    width: 100%;
    background-color: var(--gradientEndColor);
    padding: 10px;
}

.ensemble-label {
    place-self: center;
    color: var(--bckgdColor);
    font-weight: bold;
}

/* END EVENTS PAGE */

/* BEGIN FILE DRAG AND DROP */
.dropzone {
    width: 100%;
    background-color: var(--inputColor);
    border-radius: 5px;
    padding: 20px;
}

.dropzone .upload-btn {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.dropzone-change {
    background-color: var(--gradientEndColor);
}

.file-reveal {
    background-color: var(--bckgdColor);
    border-radius: 5px;
    padding: 20px;
    position: relative;
    max-width: 100%;
    margin-bottom: 10px;
}

.file-name {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--primaryColor);
}

.remove-file-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
}

.uploaded-img {
    display: block;
    width: 100%;
    margin: 0px auto;
}

.testimonial-form img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
}

.progress-wrapper {
    gap: 10px;
    padding: 20px;
}

.progress-wrapper p {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.progress {
    width: 100%;
    height: 10px;
    background-color: var(--inputColor);
    border-radius: 100vmax;
    overflow: hidden;
}

.progress-bar {
    width: 0%;
    height: 10px;
    background-color: var(--buttonColor);
}

.send-btn {
    font-size: 1em;
    font-weight: 400;
    background-color: var(--buttonColor);
    color: var(--buttonTextColor);
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
}

.send-btn:disabled {
    background-color: var(--bckgdColor) !important;
    color: var(--textSecondaryColor) !important;
}

/* END FILE DRAG AND DROP */
/* BEGIN HELP-TIP */
.help-tip {
    display: inline-block;
    text-align: center;
    background-color: var(--secondaryColor);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 24px;
    padding: 0 !important;
    cursor: default;
    margin-left: 10px;
}

.help-tip:before {
    font-family: serif;
    content: 'i';
    font-style: italic;
    font-weight: 700;
    font-size: 1.3em;
    line-height: 1.6;
    color: var(--inputColor);
}

.help-tip:hover p {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.help-tip p {
    /* The tooltip */
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    text-align: left;
    background-color: var(--primaryColor);
    padding: 20px;
    width: 300px;
    border-radius: 3px;
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    color: var(--textColor);
    line-height: 1.5em;
    position: relative;
    z-index: 1;
}

.help-tip p:after {
    /* Prevents the tooltip from being hidden */
    width: 100%;
    height: 20px;
    content: '';
    position: absolute;
    top: -17px;
    right: 0;
}

.help-tip a:link,
.help-tip a:visited {
    color: var(--bckgdColor);
    font-weight: 700;
}

/* END HELP-TIP */
/* BEGIN BOOKINGS */
.bookings-wrapper {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.booking {
    background-color: var(--buttonTextColor);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    gap: 20px;
}

.booking-label {
    font-weight: 600;
    color: var(--bckgdColor);
}

.booking-value {
    word-break: break-word;
    text-align: right;
}

/* END BOOKINGS */
/* BEGIN REORDERING LIST */
.reorder-wrapper {
    gap: 10px;
    width: 100%;
    margin-top: 20px;
}

.reorder-item {
    padding: 1rem;
    background-color: #fff;
    color: black;
    width: 100%;
    border-radius: 5px;
}

#submitReorderBtn {
    margin-top: 20px;
}

.draggable {
    cursor: move;
}

.draggable.dragging {
    background-color: var(--gradientStartColor) !important;
    opacity: 0.7;
}

/* BEGIN REORDERING LIST */

/* ---------- END CONTENT */

@media(max-width:768px) {
    :root {
        --headerHeight: 50px !important;
    }

    .header-content {
        padding: 0px 10px 0px 0px;
    }

    .header-flex {
        gap: 10px;
    }

    .header .logo-big {
        height: 50px !important;
    }

    #userName {
        display: none;
    }

    .page {
        padding-top: 70px;
    }

    .h1 {
        font-size: 40px;
    }

    .form {
        max-width: unset !important;
    }

    .form-flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .form .input-fields {
        max-width: unset !important;
    }

    .form-row {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .form-row .submit-btn {
        width: 100% !important;
    }

    .custom-input-wrapper {
        -ms-grid-row-align: flex-start;
        -ms-grid-column-align: flex-start;
        place-self: flex-start;
    }

    .width-50 {
        width: 100%;
    }
}

@media(max-width:414px) {
    .header h2 {
        font-size: 1em;
    }

    .help-tip p {
        width: 200px;
        left: -100%;
    }
}

@media(hover:hover) {


    .send-btn:hover,
    .send-btn:focus,
    .submit-btn:hover,
    .submit-btn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--buttonColor);
        box-shadow: 0px 1px 10px var(--buttonColor);
    }

    .error-btn:hover,
    .error-btn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--errColor);
        box-shadow: 0px 1px 10px var(--errColor);
    }

    .settings-item:hover,
    .settings-item:focus {
        background-color: var(--gradientEndColor);
    }

    #settingsBtn:hover svg path {
        fill: var(--buttonColor);
    }

    #forgotPass:hover,
    #forgotPass:focus {
        text-decoration: underline;
        color: var(--textSecondaryColor);
    }

    #box a:hover,
    #box a:focus {
        text-decoration: underline;
    }

    .dashboard-item:hover {
        -webkit-box-shadow: 0px 1px 10px #1e1f292b;
        box-shadow: 0px 1px 10px #1e1f292b;
    }

    .add-btn:hover svg path,
    .delete-btn:hover svg path,
    .delete-input-btn:hover svg path,
    .add-input-btn:hover svg path {
        fill: var(--buttonColor);
    }

    .remove-file-btn:hover path {
        fill: var(--textSecondaryColor);
    }

    .reorder-item:hover {
        background-color: var(--gradientEndColor);
    }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlay {
        background-color: var(--overlayColor);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
    }

}